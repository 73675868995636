import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../../constants/routerUrlConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import {
  mob_nextPage,
  mob_nextButton,
  volumeIcon,
  muteIcon,
} from '../../../constants/iconImageConstant';

export function MobPaintathonNextPage() {
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handleMuteToggle = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted; 
      setIsMuted(!isMuted); 
    } else {
      console.error('Audio reference is not available!');
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    // Play audio on mount
    if (audioElement) {
      audioElement.play().catch((err) =>
        console.error('Error playing audio:', err)
      );
    }

    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0; 
      }
    };
  }, []); 

  return (
    <>
      <Header />
      <div className="container-fluid mob-painnextbg">
        <audio id="paintathonAudio" autoPlay loop ref={audioRef}>
          <source
            src="https://assets.etvbalbharat.com/video/Channel_Song_MashUp _ETV_Bal_Bharat.mp3"
            type="audio/mp3"
          />
        </audio>

        {/* Mute/Unmute Button */}
        <button className="mob-audio" id="muteButton" onClick={handleMuteToggle}>
          <img
            id="muteIcon"
            src={isMuted ? muteIcon : volumeIcon}
            alt={isMuted ? 'Muted' : 'Unmuted'}
          />
        </button>

        <div className="col-md-12">
          <div className="mob-pain-nxtpg-cont">
            <img src={mob_nextPage} alt="paintathon" />

            <div className="mob-pain-nxtpgbt">
              <Link to={ROUTER_URL_CONSTANT.MOB_PAINT_WIN_LIST_2021}>
                <img src={mob_nextButton} alt="Next_Page" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}





















//without audio songs code comments 21/11/2024


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { ROUTER_URL_CONSTANT } from '../../../constants/routerUrlConstant';
// import { Header } from '../../Header/Header';
// import { Footer } from '../../appFooter/Footer';
// import {
//   mob_nextPage,
//   mob_nextButton,
// } from '../../../constants/iconImageConstant';


// export function MobPaintathonNextPage() {
//   return (
//     <>
//       <Header />
//       <div className="container-fluid mob-painnextbg">
//         <div className="col-md-12">
//           <div className="mob-pain-nxtpg-cont">
//             <img src={mob_nextPage} alt="paintathon" />

//             <div className="mob-pain-nxtpgbt">
//               <Link to={ROUTER_URL_CONSTANT.MOB_PAINT_WIN_LIST_2021}>
//                 <img src={mob_nextButton} alt="Next Page" />
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }
